import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { SplitColumn, PageDetails, ButtonGroup, InfoPanel } from "layout";
import { H1, H2 } from "components/text";
import { Button } from "components/inputs";
import { useFormState, usePageTitle, useSendInteraction } from "hooks";
import { toLocalISODateString } from "utils/age";
import { setRawHtml } from "content/setRawHtml";
import { invitationCodeRegex } from "constants/validations";
import { DTOPageEnum } from "constants/pageNames";
import { ApplicationModeEnumSchema, DirectTermOfferSchema, LoyaltyTierSchema } from "state/ApplicationStateSchema";
import {
    DirectTermQuoteStartContainer,
    DirectTermQuoteSubmitData,
} from "features/pages/quote/start/DirectTermQuoteStartContainer";
import { LoyaltyQuoteStartContainer, LoyaltyQuoteSubmitData } from "features/pages/quote/start/LoyaltyQuoteStartContainer";
import { useContent } from "hooks/useContent";
import { QuoteStartContentSchema } from "content/contentSchemas";
import { ActionTypeEnum } from "types/ActionTypes";
import { emptyToUndefined, toBooleanFromLowerCaseYesNo } from "utils/conversions";

export const QuoteStartStateBase = z.object({
    invitationCode: z.string().regex(invitationCodeRegex),
    membershipLength: z.number().optional(),
    planCode: z.string(),
    memberOfferAvailable: z.boolean(),
    spouseOfferAvailable: z.boolean(),

    application: z.object({
        applicantType: z.enum(["member", "spouse"]).nullish(),
        state: z
            .string()
            .length(2)
            .regex(/^[A-Z]{2}$/)
            .nullish(),
        zipCode: z.string().length(5).nullish(),
        gender: z.enum(["male", "female"]).nullish(),
        hasUsedNicotineLastYear: z.boolean().nullish(),
        email: z.string().nullish().optional(),
        dateOfBirth: z
            .string()
            .regex(/^[1-2]\d{3}-[0-1][0-9]-[0-3][0-9]$/)
            .nullish(),
    }),
});

export const DirectTermQuoteStartState = QuoteStartStateBase.merge(
    z.object({
        applicationMode: z.literal(ApplicationModeEnumSchema.enum.directterm),
        offer: DirectTermOfferSchema.array(),
    })
);

export const LoyaltyQuoteStartState = QuoteStartStateBase.merge(
    z.object({
        applicationMode: z.literal(ApplicationModeEnumSchema.enum.loyalty),
        offer: LoyaltyTierSchema.array(),
        membershipLength: z.number(),
        keyCode: z.string(),
    })
);

export const QuoteStartState = z.discriminatedUnion("applicationMode", [DirectTermQuoteStartState, LoyaltyQuoteStartState]);

export type QuoteStartData = z.infer<typeof QuoteStartState>;

const pageName = DTOPageEnum.enum.quoteStart;
const SYSTEM_ERROR_URL_PATH = "/system-error";

const QuoteStart = () => {
    const { state, dispatch } = useFormState(pageName);

    const { result: content } = useContent({
        targetSchema: QuoteStartContentSchema,
        applicationMode: state.applicationMode,
        pageName: "quote-start",
        clubCode: state?.clubSpecificData?.clubCode ?? state?.campaign?.clubCode ?? undefined,
    });

    usePageTitle("Quote Start | AAA Life");
    useSendInteraction(pageName);
    const navigate = useNavigate();
    const parseResults = QuoteStartState.safeParse(state);

    useEffect(() => {
        if (!parseResults.success) {
            console.error(parseResults.error);
            navigate(SYSTEM_ERROR_URL_PATH);
        }
    });

    if (!parseResults.success) {
        return null;
    }

    const data: QuoteStartData = parseResults.data;

    function handleClickBack() {
        navigate("/");
    }

    async function handleDispatch(submittedData: DirectTermQuoteSubmitData | LoyaltyQuoteSubmitData) {
        dispatch({
            type: ActionTypeEnum.enum.CLUB_IDENTIFIED,
            clubCode: submittedData.clubCode,
            planCode: submittedData.planCode,
        });

        if ("membershipLevel" in submittedData) {
            dispatch({
                type: ActionTypeEnum.enum.LOYALTY_QUOTES_FOUND,
                coverageOptions: submittedData.coverageOptions,
                membershipLevel: submittedData.membershipLevel,

                state: submittedData.state,
                zipCode: submittedData.zipCode,
                email: "email" in submittedData && submittedData.email ? emptyToUndefined(submittedData.email) : undefined,
                dateOfBirth: toLocalISODateString(submittedData.birthDate),
                applicantType: "member",
            });
        } else {
            dispatch({
                type: ActionTypeEnum.enum.DIRECTTERM_QUOTES_FOUND,
                coverageOptions: submittedData.coverageOptions,

                state: submittedData.state,
                zipCode: submittedData.zipCode,
                gender: submittedData.gender!,
                email: "email" in submittedData && submittedData.email ? emptyToUndefined(submittedData.email) : undefined,
                hasUsedNicotineLastYear: !!toBooleanFromLowerCaseYesNo(submittedData.hasUsedNicotineLastYear),
                dateOfBirth: toLocalISODateString(submittedData.birthDate),
                applicantType: "applicantType" in submittedData ? submittedData.applicantType || "member" : "member",
            });
        }
        navigate("/quote/results");
    }

    function onError(formError: any) {
        if (formError instanceof Error) {
            navigate(SYSTEM_ERROR_URL_PATH);
        }
    }

    return (
        <SplitColumn>
            {content && (
                <>
                    <InfoPanel>
                        <H1>{content.leftPanelTitle}</H1>
                        <section {...setRawHtml(content.leftPanelBodyHtml)} />
                    </InfoPanel>
                    <PageDetails>
                        <H2>{content.pageTitle}</H2>
                        {state.applicationMode === ApplicationModeEnumSchema.enum.directterm ? (
                            <DirectTermQuoteStartContainer
                                content={content}
                                application={data}
                                handleDispatch={handleDispatch}
                                onError={onError}
                            />
                        ) : (
                            <LoyaltyQuoteStartContainer
                                content={content}
                                application={data}
                                handleDispatch={handleDispatch}
                                onError={onError}
                            />
                        )}

                        <ButtonGroup>
                            <Button label={content.previousLabel} onClick={handleClickBack} />
                            <Button type="submit" label={content.nextLabel} color="primary" form="quote-start-form" />
                        </ButtonGroup>
                    </PageDetails>
                </>
            )}
        </SplitColumn>
    );
};

export default QuoteStart;
